import React, { useEffect, useState } from "react";
import "./NotifyActivated.css";
import { activateSubscription } from "../../utils/subscriptionUtils";
import LoadingComponent from "../LoadingComponent.jsx/LoadingComponent";

const NotifyActivated = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [queueId, setQueueId] = useState("");
  const [showProcessing, setShowProcessing] = useState(false);

  useEffect(() => {
    const activateSubscriptionAsync = async () => {
      try {
        const response = await activateSubscription();
        setQueueId(response);
        setShowProcessing(true);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    activateSubscriptionAsync();
  }, []);

  if (isLoading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
      </div>
    );
  }

  if (showProcessing) {
    return (
      <div className="loading-container">
        <LoadingComponent queueId={queueId} callback={setShowProcessing} />
      </div>
    );
  }

  return (
    <div className="thank-you-container">
      <div className="thank-you-card">
        <h1 className="thank-you-title">Welcome!</h1>
        <p className="thank-you-text">
          Thank you for choosing aludra VR. We are excited to have you on board.
        </p>
        <p className="thank-you-text">
          Please watch for an email from us with further details on next steps.
        </p>
      </div>
    </div>
  );
};

export default NotifyActivated;
