import React, { useEffect, useState } from "react";
import "./LoadingComponent.css";

const LoadingComponent = ({ queueId, callback }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const pollStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ALUDRA_API}/registerv2/register/${queueId}/status`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        console.log('Status response:', result.Data); // Debug log
        setData(result.Data);

        if (result.Data.Status === "PROCESSING" || result.Data.Status === "QUEUED") {
          setTimeout(pollStatus, 2000);
        } else if (result.Data.Status === "FAILED") {
          setError(true);
        } else {
          callback(false);
        }
      } catch (err) {
        setError(err.message);
        callback(false);
      }
    };

    if (queueId) {
      pollStatus();
    }

    return () => clearTimeout(pollStatus);
  }, [queueId, callback]);

  if (error) {
    return (
      <div className="error-container">
        <h2>Error</h2>
        <p>There was an error trying to create your account, please refer to our support page</p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="loading-initial">
        <div className="spinner-container">
          <div className="component-spinner" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  const progress = data.TotalSteps > 0 ? (data.CurrentStep / data.TotalSteps) * 100 : 0;

  return (
    <div className="progress-wrapper">
      <div className="progress-container">
        <div className="progress-header">
          <div className="header-content">
            <div className="component-spinner" />
            <h2>Processing Request</h2>
          </div>
          <p className="step-counter">
            Step {data.CurrentStep || 0} of {data.TotalSteps || 10}
          </p>
        </div>

        <div className="progress-bar-container">
          <div 
            className="progress-bar-fill" 
            style={{ width: `${progress}%` }}
          />
        </div>

        <div className="step-description">
          {data.CurrentStepDescription || "Loading..."}
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;